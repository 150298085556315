export default {
    data () {
        return {
            offsetY: 0,
            doAdjusting: true
        }
    },
    created () {
        requestAnimationFrame(this.onEachAnimationFrame)
    },
    methods: {
        onEachAnimationFrame () {

            if (!this.doAdjusting) return

            this.adjustToScreenBottom()

            requestAnimationFrame(this.onEachAnimationFrame)

        },
        adjustToScreenBottom () {
            const parentOffset = 11
            this.offsetY = -parseInt(window.document.body.clientHeight - window.innerHeight - window.scrollY - parentOffset)
        }
    },
    beforeDestroy () {
        this.doAdjusting = false
    }
}