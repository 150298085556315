<template>
    <div class="action-buttons-component w-100 d-flex align-center" :style="{ transform: `translateY(${offsetY}px)`}">
        <a class="color-primary fs-14 d-flex align-center" @click.prevent.stop="back">
            <v-icon :color="$const.color.primary" class="mr-1">
                mdi-chevron-left
            </v-icon>
            Назад к списку
        </a>
        <v-spacer />
        <div v-if="!hideSaveBtn">
            <v-btn 
                outlined
                height="40px"
                class="button-stylized"
                :disabled="anyWaiting" 
                :loading="waitingSaveAndBack" 
                @click.prevent.stop="save(true)"
            >
                Сохранить и вернуться к списку
            </v-btn>
        </div>
        <div v-if="!hideSaveBtn">
            <v-btn 
                outlined
                height="40px"
                class="button-stylized ml-5"
                :disabled="anyWaiting" 
                :loading="waitingSaveAndUpdate" 
                @click.prevent.stop="save(false)"
            >
                Сохранить и продолжить редактирование
            </v-btn>
        </div>
    </div>
</template>
<script>
import adjustToScreenBottomMixin from '@/mixins/adjustToScreenBottomMixin'

export default {
    name: 'ActionButtons',
    mixins: [adjustToScreenBottomMixin],
    props: {
        hideSaveBtn: Boolean,
        waitingSaveAndBack: Boolean,
        waitingSaveAndUpdate: Boolean,
        preventStickToBottom: { type: Boolean, default: false }
    },
    data () {
        return {}
    },
    computed: {
        anyWaiting() {
            return this.waitingSaveAndBack || this.waitingSaveAndUpdate;
        }
    },
    created () {
        if (this.preventStickToBottom) {
            this.doAdjusting = false
        }
    },
    methods: {
        back() {
            return this.$emit('back', 1);
        },
        save(back) {
            return this.$emit('save', back);
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables';

.action-buttons-component {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: $application-background;
    will-change: transform;
    height: 88px;
}

</style>